import { JSX } from "solid-js";
import c from "class-c";

import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import styles from "./Constraints.module.scss";

declare namespace Constraints {
  interface Props {
    maxWidth?: string;
    class?: string;
    children: JSX.Element;
  }
}

function Constraints(_props: Constraints.Props) {
  const {
    maxWidth,
    class: className,
    children,
  } = Constraints.PropsProvider.useMerge(_props) satisfies D;

  return (
    // Parent container
    <div
      class={c`
        ${styles["constraints"]}
        ${className}
      `}
      style={{
        "max-width": maxWidth,
      }}
    >
      {children}
    </div>
  );
}

declare namespace Constraints.Escape {
  type Props = {
    class?: string;
    padding?: boolean;
    width?: boolean;
    children: JSX.Element;
  };
}

Constraints.Escape = ({
  class: className,
  padding,
  width,
  children,
}: D<Constraints.Escape.Props>) => {
  const { maxWidth } = Constraints.PropsProvider.useContext() satisfies D;

  return (
    <div
      class={c`
        ${c(styles)`
          escape
          ${{ padding, width }}
        `}
        ${className}
      `}
    >
      <div style={{ "max-width": maxWidth }}>{children}</div>
    </div>
  );
};

declare namespace Constraints.Only {
  type Props = {
    class?: string;
    padding?: boolean;
    width?: boolean;
    children: JSX.Element;
  };
}

Constraints.Only = ({
  class: className,
  padding,
  width,
  children,
}: D<Constraints.Only.Props>) => {
  const { maxWidth } = Constraints.PropsProvider.useContext() satisfies D;

  return (
    <div
      class={c`
        ${c(styles)`
          only
          ${{ padding, width }}
        `}
        ${className}
      `}
      style={width ? { "max-width": maxWidth } : void 0}
    >
      {children}
    </div>
  );
};

Constraints.PropsProvider =
  createPropsProvider<Constraints.Props>("Constraints");

Constraints.cssVars = {
  sidePadding: "var(--constraints\\:padding-x)",
};

export default Constraints;
